const Navigation = ({ processCmd }) => {
  return (
    <nav
      style={{
        fontFamily: "monospace",
        color: "white",
        padding: "15px",
        fontSize: "1.2em",
      }}
    >
      <h2 style={{ margin: "0 0 15px 0" }}>NAVIGATION:</h2>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <li style={{ margin: "5px" }} onClick={() => processCmd("neofetch")}>about me</li>
        <li style={{ margin: "5px" }} onClick={() => processCmd("cat /music/README.txt")}>music</li>
        <li style={{ margin: "5px" }} onClick={() => processCmd("cat /education/README.txt")}>education</li>
        <li style={{ margin: "5px" }} onClick={() => processCmd("cat /programming/README.txt")}>programming</li>
      </ul>
    </nav>
  );
};

export default Navigation