import "../styles.css";
import { useRef, useEffect } from "react";

const CommandInput = ({ workingDir, output, inputChange, processChange, input, prompt }) => {
  const outputRef = useRef(null);

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);

  return (
    <div style={{ 
      display: "flex", 
      flexDirection: "column", 
      height: "100%", 
      overflow: "hidden"
    }}>
      <div 
        ref={outputRef}
        style={{ 
          flex: 1, 
          overflowY: "auto", 
          padding: "10px",
          marginBottom: "10px"
        }}
      >
        {output.map((line, index) => (
          <pre key={index} style={{ margin: 0, whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            {line}
          </pre>
        ))}
      </div>
      <div style={{ 
        display: "flex", 
        alignItems: "center", 
        padding: "10px", 
        borderTop: "1px solid #333"
      }}>
        <span>{prompt} {workingDir} % </span>
        <input
          ref={input}
          type="text"
          onChange={inputChange}
          onKeyDown={processChange}
          style={{ 
            flex: 1,
            background: "transparent",
            border: "none",
            color: "inherit",
            font: "inherit",
            outline: "none",
          }}
        />
      </div>
    </div>
  );
};
export default CommandInput;
