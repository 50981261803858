import { createStore } from "redux";
import Directory from "../utilities/Directory";

const initialState = {
  fsRoot: new Directory("~"),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_DIR": {
      const newDir = new Directory(action.payload.name);
      if (action.payload.location === "/") {
        state.fsRoot.addChild(newDir);
        return state;
      }
      let pathSplit = action.payload.location.split("/");
      pathSplit = pathSplit.slice(1, pathSplit.length)
      let depth = 0;
      let curr = state.fsRoot;
      while (depth < pathSplit.length) {
        for (let i = 0; i < curr.children.length; i++) {
          if (curr.children[i].name == pathSplit[depth]) {
            depth++;
            curr = curr.children[i];
            break;
          }
        }
      }
      curr.addChild(newDir);
      return state;
    }
    case "ADD_FILE": {
      if (action.payload.location === "/") {
        state.fsRoot.addFile(action.payload.name);
        return state;
      }
      let pathSplit = action.payload.location.split("/");
      pathSplit = pathSplit.slice(1, pathSplit.length)
      let depth = 0;
      let curr = state.fsRoot;
      while (depth < pathSplit.length) {
        for (let i = 0; i < curr.children.length; i++) {
          if (curr.children[i].name == pathSplit[depth]) {
            depth++;
            curr = curr.children[i];
            break;
          }
        }
      }
      curr.addFile(action.payload.name);
      return state;
    }
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
