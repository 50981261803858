class Directory {
  constructor(name) {
    this.name = name;
    this.children = [];
    this.files = []
    this.parent = null;
    return this;
  }

  setParent(parent) {
    this.parent = parent;
    return this;
  }

  addFile(name) {
    this.files.push(name)
  }

  addChild(child) {
    this.children.push(child);
    child.setParent(this);
    return this;
  }
}
export default Directory